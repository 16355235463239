<template>
    <div class="max-width">
      <div v-html="$store.state.bottomInfo.yiliao"></div>
    </div>
</template>

<script>
    export default {
        name: "licenseOfmedical"
    }
</script>

<style scoped>

</style>
